<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">{{listTit}}</h2>
                <div class="hosrow">
                    <searchDic @searchList="searchList" @searChange="searChange"  />
                    <div>
                        <el-button type="primary" @click="depaddKeshi">新增专项奖励指标</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <!-- <el-table-column prop="encoded" label="指标编码" align="center" width="120">
                    </el-table-column> -->
                    <el-table-column prop="name" label="指标名称" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="intension" label="指标内涵" align="center" width="300">
                        <template slot-scope="scope">
                            <div class="remakStree">{{scope.row.intension}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="suggestedScore" label="建议分值" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="dataType" label="指标数据类型" align="center" width="110">
                        <template slot-scope="scope">
                            <div v-if="scope.row.dataType=='0'">医生</div>
                            <div v-else-if="scope.row.dataType=='1'">护士</div>
                            <div v-else-if="scope.row.dataType=='2'">技师</div>
                            <div v-else-if="scope.row.dataType=='3'">通用</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="characteristic" label="特性" align="center" width="80">
                        <!-- 指标特性（0加分 1减分 2固定分值  3评分） -->
                        <template slot-scope="scope">
                            <div v-if="scope.row.characteristic=='0'">系数加分</div>
                            <div v-else-if="scope.row.characteristic=='1'">系数减分</div>
                            <div v-else-if="scope.row.characteristic=='2'">固定金额</div>
                            <div v-else-if="scope.row.characteristic=='3'">评分</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" align="center" width="70">
                        <!-- 单位（0次  1人/次  2半天/次） -->
                        <template slot-scope="scope">
                            <div v-if="scope.row.unit=='0'">次</div>
                            <div v-else-if="scope.row.unit=='1'">人/次</div>
                            <div v-else-if="scope.row.unit=='2'">半天/次</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="score" label="分值" align="center">
                    </el-table-column> -->
                    <el-table-column prop="explain" label="指标说明" align="center" width="300">
                        <template slot-scope="scope">
                            <div class="remakStree">{{scope.row.explain}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="指标状态" align="center" width="100">
                        <template slot-scope="scope">
                            <!-- 指标状态（0启用  1停用） -->
                            <div v-if="scope.row.status=='0'">
                                <el-switch v-model="value" active-color="#409EFF" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>
                            <div v-else-if="scope.row.status=='1'">
                                <el-switch v-model="novalue" active-color="#409EFF" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" align="center" width="160">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="130" fixed="right">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增人员信息弹出框 -->
        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" top="1vh" width="400px" :before-close="AdddialogVisibleClose">
            <secondadd :dieId='dieId' :diaBtn='diaBtn' :wardId='wardId' :listTit='listTit' ref='addinman' :EditDateModel='AddDateModel' @listworkIn='vidbsiList' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import secondadd from "../AddInMan/secondadd.vue";
import searchDic from "../AddInMan/searchDic.vue";
export default {
    data() {
        return {
            listTit: "专项奖励指标",
            value: true,
            novalue: false,
            diaBtn: "新增",
            diaTit: "新增专项奖励指标",
            // 点击添加科室弹出框
            AdddialogVisible: false,
            AddDateModel: {},
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "", //病区id
            nameLike: "",
            searstatus: "",
            dieId: "2",
        };
    },
    components: {
        secondadd,
        searchDic,
    },
    created() {
        this.vidbsiList();
    },
    methods: {
        searchList(val) {
            // console.log(val);
            this.nameLike = val;
            this.pageNum = 1;
            this.vidbsiList();
        },
        searChange(val) {
            // console.log(val);
            this.searstatus = val;
            this.pageNum = 1;
            this.vidbsiList();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.vidbsiList();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
            setTimeout(() => {
                this.$refs.addinman.perdindBy();
            }, 0);
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.$refs.addinman.clearadd();
            this.diaTit = "新增专项奖励指标";
            this.diaBtn = "新增";
        },

        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.thirDel(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.vidbsiList();
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            // console.log(row)
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.diaTit = "修改专项奖励指标";
            this.AdddialogVisible = true;
            this.AddDateModel = row;
            setTimeout(() => {
                this.$refs.addinman.perdindBy();
            }, 0);
        },
        // 分页显示病区列表数据
        async vidbsiList() {
            this.AdddialogVisible = false;
            this.diaBtn = "新增";
            this.diaTit = "新增专项奖励指标";
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                indicatorSecondLibraryId: "",
                type: this.dieId, //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                workloadIndicatorType: "",
                deleteFlag: "0",
                name: this.nameLike,
                status: this.searstatus,
            };
            let { data: res } = await this.$axios.thirdList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input .el-input__inner {
    width: 220px;
}

/deep/ .el-textarea__inner,
/deep/ .el-textarea {
    width: 220px !important;
}
</style>
